<template>
  <div class="relative h-[70px]">
    <nav
      id="mainNav"
      class="fixed z-10 pb-[50px] pt-[30px] left-0 bottom-0 md:top-0 w-full md:w-[240px] md:max-w-[400px] flex md:flex-col justify-evenly md:justify-start md:items-start items-center bg-[rgba(50,26,90,0.9)] md:bg-bgDark md:bg-bgLight h-[70px] md:h-screen backdrop-blur-sm overflow-hidden"
    >
      <div class="hidden md:flex my-11 ml-[24px] md:centerAll w-full relative">
        <a href="/">
          <img src="@/assets/logo.png" class="w-[186px]" />
        </a>
      </div>
      <div
        :style="{ top: getTopLevel }"
        class="hidden md:block w-[4px] bg-primary absolute right-0 h-[40px] microTran"
      />
      <router-link
        to="/"
        class="flex flex-col justify-start w-[20%] md:w-full md:flex-row md:ml-[24px] my-5"
      >
        <span
          class="text-primary defaultIconSize self-center transitionMenu"
          :class="
            active == 'Home'
              ? ['material-icons', 'active']
              : 'material-icons-outlined'
          "
          >home</span
        >
        <p
          class="self-center text-primary text-xxxs md:text-sm md:ml-3 md:md:hover:translate-x-3 microTran"
          :class="active == 'Home' ? 'active' : ''"
        >
          Home
        </p>
      </router-link>
      <router-link
        to="/top100"
        class="flex flex-col justify-start w-[20%] md:w-full md:flex-row md:ml-[24px] my-5"
      >
        <span
          class="text-primary defaultIconSize self-center transitionMenu"
          :class="
            active == 'Top 100'
              ? ['material-icons', 'active']
              : 'material-icons-outlined'
          "
          >insights</span
        >
        <p
          class="self-center text-primary text-xxxs md:text-sm md:ml-3 md:md:hover:translate-x-3 microTran"
          :class="active == 'Top 100' ? 'active' : ''"
        >
          Top 100
        </p>
      </router-link>
      <router-link
        to="/stream"
        class="flex flex-col justify-start w-[20%] md:w-full md:flex-row md:ml-[24px] my-5"
      >
        <span
          class="text-primary defaultIconSize self-center transitionMenu"
          :class="
            active == 'Stream'
              ? ['material-icons', 'active']
              : 'material-icons-outlined'
          "
          >feed</span
        >
        <p
          class="self-center text-primary text-xxxs md:text-sm md:ml-3 md:md:hover:translate-x-3 microTran"
          :class="active == 'Stream' ? 'active' : ''"
        >
          Stream
        </p>
      </router-link>
      <router-link
        to="/musterdepot"
        class="flex flex-col justify-start w-[20%] md:w-full md:flex-row md:ml-[24px] my-5"
      >
        <span
          class="text-primary defaultIconSize self-center transitionMenu"
          :class="
            active == 'Musterdepot'
              ? ['material-icons', 'active']
              : 'material-icons-outlined'
          "
          >account_balance_wallet</span
        >
        <p
          class="self-center text-primary text-xxxs md:text-sm md:ml-3 md:md:hover:translate-x-3 microTran"
          :class="active == 'Musterdepot' ? 'active' : ''"
        >
          Musterdepot
        </p>
      </router-link>
      <router-link
        to="/mitteilungen"
        class="flex flex-col justify-start w-[20%] md:w-full md:flex-row md:ml-[24px] my-5 relative"
      >
        <span
          class="relative text-primary defaultIconSize self-center transitionMenu"
          :class="
            active == 'Mitteilungen'
              ? ['material-icons', 'active']
              : 'material-icons-outlined'
          "
        >
          notifications
          <span
            v-if="getNotificationAmount >= 1"
            class="absolute left-1 top-1 rounded-full left-5 px-1 py-0.5 text-xxxs font-bold text-white bg-accent centerAll"
            style="font-family: var(--font)"
          >
            {{ getNotificationAmount }}
          </span>
        </span>
        <p
          class="self-center text-primary text-xxxs md:text-sm md:ml-3 md:md:hover:translate-x-3 microTran"
          :class="active == 'Mitteilungen' ? 'active' : ''"
        >
          Mitteilungen
        </p>
      </router-link>
      <div
        class="absolute bottom-[20px] left-[20px] hidden md:flex hover:translate-x-3 microTran"
      >
        <router-link
          to="/usersettings"
          class="flex bg-primary py-2 px-2 pr-5 rounded-full"
        >
          <userImage class="userimage-defaultSize" />
          <div class="ml-[10px] centerAll">
            <p
              class="text-secondary transitionMenu"
              :class="active == 'Einstellungen' ? 'active' : ''"
            >
              Mein Profil
            </p>
          </div>
        </router-link>
      </div>
    </nav>
  </div>
</template>

<script>
import userImage from "@/components/UserImage";

export default {
  title: "MainTabbar",
  data() {
    return {
      active: "",
    };
  },
  mounted() {
    this.active = this.$route.name;
  },
  components: {
    userImage,
  },
  computed: {
    getNotificationAmount() {
      return this.$store.state.notificationsAmount;
    },
    getTopLevel() {
      if (this.$route.matched[0] != undefined) {
        if (this.$route.matched[0].path == "/") {
          return "160px";
        } else if (this.$route.matched[0].path == "/top100") {
          return "235px";
        } else if (this.$route.matched[0].path == "/stream") {
          return "310px";
        } else if (this.$route.matched[0].path == "/musterdepot") {
          return "385px";
        } else if (this.$route.matched[0].path == "/mitteilungen") {
          return "460px";
        }
      }
      return "-40px";
    },
  },
  watch: {
    $route(newRouteValue) {
      this.active = newRouteValue.name;
    },
  },
};
</script>

<style scoped>
.active {
  color: white;
}
.transitionMenu {
  transition: all 0.3s ease-out;
}
</style>
