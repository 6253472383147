import { createStore } from "vuex";
import axios from "axios";
import apiFetch from "@wordpress/api-fetch";

const baseUrl = "https://chartexperten.de/wp-json/";
const baseUrlv2 = "https://chartexperten.de/wp-json/wp/v2/";

export default createStore({
  state: {
    user: null,
    coins: {
      top100: null,
      favourites: null,
    },
    news: {
      news: null,
      help: null,
      school: null,
      paging: {
        news: 1000,
        help: 1000,
      },
    },
    tagesThread: {
      userlist: null,
      current: null,
      archieve: null,
    },
    musterdepot: {
      depot: null,
      transactions: null,
    },
    notifications: null,
    notificationsAmount: 0,
    webinars: null,
    customer_portal: null,
    screenSizes: {
      height: null,
      width: null,
      mode: null,
    },
  },
  getters: {},
  mutations: {
    SET_PAGING(state, data) {
      state.news.paging[data.type] = data.total;
    },
    // USER //
    SET_USER(state, user) {
      state.user = user;
    },
    DELETE_USER() {
      //state.user = null;
    },
    SET_USER_INFO(state, userinfo) {
      state.user.info = userinfo;
    },
    SET_USER_MEMBERSHIP(state, userinfo) {
      state.user.membership = userinfo;
    },
    // NEWS //
    SET_NEWS_LIST(state, list) {
      state.news.news = list;
    },
    ADD_NEWS_LIST(state, list) {
      state.news.news.push(...list);
    },
    SET_HELP_LIST(state, list) {
      state.news.help = list;
    },
    ADD_HELP_LIST(state, list) {
      state.news.help.push(...list);
    },
    SET_SCHOOL_LIST(state, list) {
      state.news.school = list;
    },
    // NEWS POST //
    SET_LEARNINGGOALS(state, list) {
      state.user.info.acf.lernziele = list;
    },
    // TOP 100 //
    SET_TOP100_COINS(state, top100Coins) {
      state.coins.top100 = top100Coins;
    },
    // FAV COINS //
    SET_TOP100_COINS_FAVOURITES(state, top100Coins) {
      state.coins.favourites = top100Coins;
    },
    SET_TOP100_COINS_IN_USER(state, data) {
      state.user.info.acf.subscribed_coins = data;
    },
    // TAGESTHREAD //
    SET_TAGESTHREAD(state, content) {
      state.tagesThread.current = content;
    },
    SET_TAGESTHREADCOMMENTS_META(state, content) {
      state.tagesThread.current.meta = content;
    },
    SET_TAGESTHREADCOMMENTS(state, content) {
      state.tagesThread.current.comments = content;
    },
    PUSH_TAGESTHREADCOMMENTS(state, content) {
      if (content.parent == 0) {
        state.tagesThread.current.comments.unshift(content);
      } else {
        state.tagesThread.current.comments.forEach((comment) => {
          if (comment.id == content.parent) {
            if (comment._embedded.children == undefined) {
              if (comment._embedded.children == undefined) {
                comment._embedded.children = [];
              }
              comment._embedded.children[0] = [];
            }
            comment._embedded.children[0].unshift(content);
          }
        });
      }
    },
    ADD_TAGESTHREADCOMMENTS(state, content) {
      function removeDuplicates(array) {
        let newArray = [];
        array.forEach((item) => {
          if (
            !newArray.some((otherItem) => {
              if (item._embedded.children != undefined) {
                return (
                  otherItem.id === item.id &&
                  otherItem._embedded.children[0].length ===
                    item._embedded.children[0].length
                );
              }
              return otherItem.id === item.id;
            })
          ) {
            newArray.push(item);
          }
        });
        return newArray;
      }
      if (typeof state.tagesThread.current.comments === "undefined") {
        state.tagesThread.current.comments = content;
      } else {
        let newArray = state.tagesThread.current.comments.concat(content);
        state.tagesThread.current.comments = removeDuplicates(newArray);
      }
    },
    SET_TAGESTHREADARCHIEVE(state, content) {
      state.tagesThread.archieve = content;
    },
    SET_TAGESTHREAD_SUBSCRIPTIONS(state, content) {
      state.user.info.acf.subscribed_conversations = content;
    },
    // USER LIST //
    SET_USER_LIST(state, content) {
      state.tagesThread.userlist = content;
    },
    //MUSTERDEPOT & TRANSACTIONS
    SET_MUSTERDEPOT(state, content) {
      state.musterdepot.depot = content;
    },
    SET_TRANSACTIONS(state, content) {
      state.musterdepot.transactions = content;
    },
    // NOTIFICATIONS
    SET_NOTIFICATIONS(state, content) {
      state.notifications = content;
    },
    SET_NOTIFICATIONS_AMOUNT(state, amount) {
      state.notificationsAmount = amount;
    },
    SET_WEBINARS(state, content) {
      state.webinars = content;
    },
    SET_CUSTOMER_PORTAL(state, content) {
      state.customer_portal = content;
    },
    /* CONFIG */
    SET_WIDTH(state, amount) {
      state.screenSizes.width = amount;
    },
    SET_HEIGHT(state, amount) {
      state.screenSizes.height = amount;
    },
    SET_RESPONSIVE_MODE(state, content) {
      state.screenSizes.mode = content;
    },
  },
  actions: {
    paging: function ({ commit }, data) {
      commit("SET_PAGING", data);
    },
    // LOGIN //
    login: function ({ commit }, credentials) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await axios.post(
            `https://chartexperten.de/wp-json/jwt-auth/v1/token`,
            credentials
          );
          commit("SET_USER", data);
          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    },
    // VALIDATION //
    async validate({ state }, token) {
      if (token != undefined) {
        const valid = await axios({
          url: baseUrl + "jwt-auth/v1/token/validate",
          method: "post",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return valid;
      } else {
        return axios({
          url: baseUrl + "jwt-auth/v1/token/validate",
          method: "post",
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        });
      }
    },
    // USER GET REQUESTS //
    requestUserInformation: function ({ state, commit }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await axios({
            url: baseUrlv2 + "users/me?acf_format=standard",
            method: "GET",
            headers: {
              Authorization: `Bearer ${state.user.token}`,
            },
          });
          commit("SET_USER_INFO", data);
          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    },
    requestGetUser: async function ({ state }, id) {
      return apiFetch({
        path: baseUrlv2 + "users/" + id,
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      }).then((res) => {
        return res;
      });
    },
    // USER POST REQUESTS //
    setUserInformation: async function ({ state }, information) {
      let requestData = {
        acf: {
          status: information.status,
        },
      };
      apiFetch({
        path: baseUrlv2 + "users/me", //+ state.user.info.id,
        method: "POST",
        data: requestData,
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      }).then((res) => {
        return res;
      });
    },
    setUserImage: async function ({ state }, file) {
      if (file.file.name == undefined) {
        window.alert("Bitte neu laden!");
      } else {
        let requestData;
        try {
          let formData = new FormData();
          let imageResponse;
          formData.append("file", file.file);
          console.log(
            await axios({
              url: baseUrl + "chartexperten/v1/user_image",
              method: "POST",
              data: formData,
              headers: {
                Authorization: `Bearer ${state.user.token}`,
                "content-disposition": "attachment; filename=" + file.file.name,
              },
            })
          );
          // first upload image
          try {
            imageResponse = await axios({
              url: baseUrl + "chartexperten/v1/user_image",
              method: "POST",
              data: formData,
              headers: {
                Authorization: `Bearer ${state.user.token}`,
                "content-disposition": "attachment; filename=" + file.file.name,
              },
            });
          } catch (e) {
            return false;
          }
          // then set Image
          requestData = {
            acf: {
              user_image: imageResponse.data.id,
            },
          };
        } catch (e) {
          return false;
        }
        try {
          apiFetch({
            path: baseUrlv2 + "users/" + state.user.info.id,
            method: "POST",
            data: requestData,
            headers: {
              Authorization: `Bearer ${state.user.token}`,
            },
          }).then((res) => {
            return res;
          });
          return true;
        } catch (e) {
          return false;
        }
      }
    },
    // TOP 100 //
    async requestTop100Coins({ state, commit }) {
      //here request to get top 100 coins, then map with acf ID's, create Array with favourites in it and
      //commit to store. To change value: request change -> onsuccess set Store state
      const response = await axios({
        url: baseUrl + "chartexperten/v1/coin/top100",
        method: "GET",
      });
      if (response.status == 200) {
        commit("SET_TOP100_COINS", response.data);
        const subscribed = state.user.info.acf.subscribed_coins;
        const top100 = response.data;
        let subscribedCoins = [];
        if (subscribed.length > 0) {
          subscribed.forEach(function (favCoin) {
            top100.find((coin) => {
              if (favCoin.slug.split("-")[0] === coin.code.toLowerCase()) {
                subscribedCoins.push(coin);
              }
            });
          });
          commit("SET_TOP100_COINS_FAVOURITES", subscribedCoins);
        } else {
          commit("SET_TOP100_COINS_FAVOURITES", null);
        }
        return true;
      }
    },
    async requestSingleTop100Details({ state }, info) {
      // eslint-disable-next-line no-unused-vars
      let urlEndpoint;
      if (info.type == "chart") {
        urlEndpoint = "chart?coin_slug=" + info.coin;
      } else if (info.type == "fundament") {
        urlEndpoint = "fundament?coin_slug=" + info.coin;
      }
      const response = await axios({
        url: baseUrlv2 + urlEndpoint + "&_embed",
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      return response;
    },
    // SET TOP100 FAVOURITES
    setTop100Favourites: async function ({ state }, data) {
      const responseData = {
        coin: data.slug,
        subscribe: data.like,
      };
      return await apiFetch({
        path: baseUrl + "chartexperten/v1/coin/subscribe",
        method: "POST",
        data: responseData,
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      }).then((res) => {
        return res;
      });
    },
    // NEWS //
    async requestNewsHelpListFiltered({ state, commit }, data) {
      const response = await axios({
        url:
          baseUrlv2 +
          data.type +
          "?_embed&search=" +
          data.title +
          "&required_level=" +
          data.level +
          "&per_page=100",
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      if (response.status == 200) {
        data.type === "news"
          ? commit("SET_NEWS_LIST", response.data)
          : commit("SET_HELP_LIST", response.data);
        return true;
      } else {
        return false;
      }
    },
    async requestNewsList({ state, commit }) {
      const response = await axios({
        url: baseUrlv2 + "news?_embed",
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      if (response.status == 200) {
        commit("SET_NEWS_LIST", response.data);
        return true;
      } else {
        return false;
      }
    },
    async requestNewsListNext({ state, commit }, pageNumber) {
      const response = await axios({
        url: baseUrlv2 + "news?_embed&page=" + pageNumber,
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      if (response.status == 200) {
        commit("ADD_NEWS_LIST", response.data);
        return response;
      } else {
        return false;
      }
    },
    async requestNewsListPremium({ state, commit }) {
      const response = await axios({
        url: baseUrlv2 + "news?_embed&required_level=2",
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      if (response.status == 200) {
        commit("ADD_NEWS_LIST", response.data);
        return response;
      } else {
        return false;
      }
    },
    async requestNewsListPremiumNext({ state, commit }, pageNumber) {
      const response = await axios({
        url: baseUrlv2 + "news?_embed&required_level=2&page=" + pageNumber,
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      if (response.status == 200) {
        commit("ADD_NEWS_LIST", response.data);
        return response;
      } else {
        return false;
      }
    },
    // HELP //
    async requestHelpList({ state, commit }) {
      const response = await axios({
        url: baseUrlv2 + "help?_embed",
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      if (response.status == 200) {
        commit("SET_HELP_LIST", response.data);
        return true;
      } else {
        return false;
      }
    },
    async requestHelpListNext({ state, commit }, pageNumber) {
      const response = await axios({
        url: baseUrlv2 + "help?_embed&page=" + pageNumber,
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      if (response.status == 200) {
        commit("ADD_HELP_LIST", response.data);
        return response;
      } else {
        return false;
      }
    },
    async requestHelpListPremium({ state, commit }) {
      const response = await axios({
        url: baseUrlv2 + "help?_embed&required_level=2",
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      if (response.status == 200) {
        commit("ADD_HELP_LIST", response.data);
        return response;
      } else {
        return false;
      }
    },
    async requestHelpListPremiumNext({ state, commit }, pageNumber) {
      const response = await axios({
        url: baseUrlv2 + "help?_embed&required_level=2&page=" + pageNumber,
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      if (response.status == 200) {
        commit("ADD_HELP_LIST", response.data);
        return response;
      } else {
        return false;
      }
    },
    // PREMIUM DISCUSSION //
    async requestPremiumDiscussionCommentInfo({ state }) {
      try {
        const response = await axios({
          url:
            baseUrlv2 +
            "comments?post=" +
            state.tagesThread.current.id +
            "&per_page=1",
          method: "GET",
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        });
        return response;
      } catch (e) {
        return false;
      }
    },
    async requestPremiumDiscussionComments({ state }, postId) {
      try {
        let responseArray = [];
        let response = await axios({
          url: baseUrlv2 + "comments?post=" + postId + "&per_page=100",
          method: "GET",
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        });
        responseArray.push(...response.data);
        const pages = Number(response.headers["x-wp-totalpages"]);
        if (pages > 1) {
          for (let i = 2; i <= pages; i++) {
            response = await axios({
              url:
                baseUrlv2 +
                "comments?post=" +
                postId +
                "&per_page=100&page=" +
                i +
                "&_embed",
              method: "GET",
              headers: {
                Authorization: `Bearer ${state.user.token}`,
              },
            });
            responseArray.push(...response.data);
          }
        }
        return response.data;
      } catch (e) {
        return false;
      }
    },
    // PREMIUM DISCUSSION - POST //
    async requestAddCommentToPremiumDiscussion({ state }, data) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // eslint-disable-next-line no-unused-vars
          const { response } = await axios.post(baseUrlv2 + "comments", data, {
            headers: {
              Authorization: `Bearer ${state.user.token}`,
            },
          });
          resolve(true);
        } catch (e) {
          reject(e);
        }
      });
    },
    async requestDeleteCommentFromPremiumDiscussion({ state }, data) {
      const newData = {
        content: data.content,
      };
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // eslint-disable-next-line no-unused-vars
          const { response } = await axios.put(
            baseUrlv2 + "comments/" + data.id,
            newData,
            {
              headers: {
                Authorization: `Bearer ${state.user.token}`,
              },
            }
          );
          resolve(true);
        } catch (e) {
          reject(e);
        }
      });
    },
    // SCHOOL //
    async requestSchoolList({ state, commit }) {
      const response = await axios({
        url: baseUrlv2 + "school/?per_page=100&_embed",
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      if (response.status == 200) {
        commit("SET_SCHOOL_LIST", response.data);
        return true;
      } else {
        return false;
      }
    },
    // NEWS POST REQUESTS //
    setUserLearningGoalsFrontend: function ({ commit }, learningGoals) {
      commit("SET_LEARNINGGOALS", learningGoals);
    },
    setUserLearningGoals: async function ({ state }, learningGoals) {
      let requestData = {
        acf: {
          lernziele: learningGoals,
        },
      };
      await apiFetch({
        path: baseUrlv2 + "users/" + state.user.info.id,
        method: "POST",
        data: requestData,
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      }).then((res) => {
        //commit("SET_LEARNINGGOALS", res.acf.lernziele);
        return res.acf.lernziele;
      });
    },
    // TAGESTHREAD //
    async requestTagesthread({ state, commit }) {
      try {
        const response = await axios({
          url: baseUrlv2 + "posts?_embed&acf_format=standard&per_page=1",
          method: "GET",
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        });
        commit("SET_TAGESTHREAD", response.data[0]);
        return response.data[0];
      } catch (e) {
        return false;
      }
    },
    async requestTagesthreadCommentInfo({ state, commit }) {
      try {
        const response = await axios({
          url:
            baseUrlv2 +
            "comments?post=" +
            state.tagesThread.current.id +
            "&per_page=1",
          method: "GET",
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        });
        commit("SET_TAGESTHREADCOMMENTS_META", response);
        return true;
      } catch (e) {
        return false;
      }
    },
    async requestTagesthreadComments(
      { state, commit },
      postId,
      pageNumber = 1,
      parentId = 0
    ) {
      try {
        let response = await axios({
          url:
            baseUrlv2 +
            "comments?post=" +
            postId +
            "&per_page=100&page=" +
            pageNumber +
            "&parent=" +
            parentId +
            "&_embed",
          method: "GET",
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        });
        commit("ADD_TAGESTHREADCOMMENTS", response.data);
        return response.data;
      } catch (e) {
        return false;
      }
    },
    async requestTagesthreadCommentsFull({ state, commit }, postId) {
      try {
        let responseArray = [];
        let response = await axios({
          url:
            baseUrlv2 +
            "comments?post=" +
            postId +
            "&parent=0&per_page=100&_embed",
          method: "GET",
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        });
        responseArray.push(...response.data);
        const pages = Number(response.headers["x-wp-totalpages"]);
        if (pages > 1) {
          for (let i = 2; i <= pages; i++) {
            response = await axios({
              url:
                baseUrlv2 +
                "comments?post=" +
                postId +
                "&parent=0&per_page=100&page=" +
                i +
                "&_embed",
              method: "GET",
              headers: {
                Authorization: `Bearer ${state.user.token}`,
              },
            });
            responseArray.push(...response.data);
          }
        }
        commit("SET_TAGESTHREADCOMMENTS", responseArray);
        return response.data;
      } catch (e) {
        return false;
      }
    },
    async requestUserList({ state, commit }) {
      try {
        const responseArray = [];
        let response = await axios({
          url: baseUrlv2 + "users?per_page=100",
          method: "GET",
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        });
        responseArray.push(...response.data);
        const pages = Number(response.headers["x-wp-totalpages"]);
        if (pages > 1) {
          for (let i = 2; i <= pages; i++) {
            response = await axios({
              url: baseUrlv2 + "users" + "?per_page=100&page=" + i,
              method: "GET",
              headers: {
                Authorization: `Bearer ${state.user.token}`,
              },
            });
            responseArray.push(...response.data);
          }
        }
        commit("SET_USER_LIST", response.data);
        return true;
      } catch (e) {
        return false;
      }
    },
    // TAGESTHREAD - POST //
    async requestAddCommentToTagesthread({ state }, data) {
      try {
        return await axios.post(baseUrlv2 + "comments?_embed", data, {
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        });
      } catch (e) {
        if (e.response.data.code == "comment_flood") {
          return "comment_flood";
        } else {
          return false;
        }
      }
    },
    async requestDeleteCommentFromTagesthread({ state }, data) {
      const newData = {
        content: data.content,
      };
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // eslint-disable-next-line no-unused-vars
          const { response } = await axios.put(
            baseUrlv2 + "comments/" + data.id,
            newData,
            {
              headers: {
                Authorization: `Bearer ${state.user.token}`,
              },
            }
          );
          resolve(true);
        } catch (e) {
          reject(e);
        }
      });
    },
    // TAGESTHREAD ARCHIEVE //
    async requestTagesarchieve({ state, commit }) {
      try {
        const response = await axios({
          url: baseUrlv2 + "posts?_embed&per_page=50",
          method: "GET",
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        });
        if (response.status == 200) {
          commit("SET_TAGESTHREADARCHIEVE", response.data);
        }
      } catch (e) {
        return false;
      }
    },
    async requestSingleTagesarchieve({ state }, id) {
      try {
        const response = await axios({
          url:
            baseUrlv2 +
            "posts?_embed&acf_format=standard&per_page=1&?include[]=" +
            id,
          method: "GET",
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        });
        return response.data[0];
      } catch (e) {
        return false;
      }
    },
    async requestSingleTagesarchieveComments({ state }, postId) {
      try {
        const response = await axios({
          url: baseUrlv2 + "comments?post=" + postId + "&per_page=100&_embed",
          method: "GET",
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        });
        return response.data;
      } catch (e) {
        return false;
      }
    },
    // THREAD SUBSCRIPTION //
    async requestSubscription({ state }, data) {
      // eslint-disable-next-line no-async-promise-executor
      return await apiFetch({
        path: baseUrl + "chartexperten/v1/comment/subscribe",
        method: "POST",
        data: data,
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      }).then((res) => {
        return res;
      });
    },
    promiseTagesthreadRocket({ commit }, content) {
      commit("SET_TAGESTHREAD_SUBSCRIPTIONS", content);
    },
    // REACTIONS //
    async requestReaction({ state }, data) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          // eslint-disable-next-line no-unused-vars
          const { response } = await axios.post(
            baseUrl + "chartexperten/v1/comment/like",
            data,
            {
              headers: {
                Authorization: `Bearer ${state.user.token}`,
              },
            }
          );
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    // MEMBERSHIP ACCESS //
    async requestHasMembershipAccess({ state }, accessIds) {
      const response = await axios({
        url:
          baseUrl +
          "pmpro/v1/has_membership_access?post_id=" +
          accessIds.postId +
          "&user_id=" +
          accessIds.userId,
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      return response;
    },
    // MUSTERDEPOT //
    async requestMusterdepot({ state, commit }) {
      const response = await axios({
        url: baseUrlv2 + "musterdepot",
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      if (response.status == 200) {
        commit("SET_MUSTERDEPOT", response.data);
        return true;
      } else {
        return false;
      }
    },
    // TRANSACTIONS //
    async requestTransactions({ state, commit }) {
      const response = await axios({
        url: baseUrlv2 + "transaktionen",
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      if (response.status == 200) {
        commit("SET_TRANSACTIONS", response.data);
        return true;
      } else {
        return false;
      }
    },
    // NOTIFICATIONS //
    async getRequestNotifications({ state, commit }) {
      const response = await axios({
        url: baseUrl + "chartexperten/v1/notifications?per_page=100",
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      if (response.status == 200) {
        let notificationCounter = 0;
        response.data.forEach((notification) => {
          notificationCounter += notification.new ? 1 : 0;
        });
        commit("SET_NOTIFICATIONS_AMOUNT", notificationCounter);
        commit("SET_NOTIFICATIONS", response.data);
        return true;
      } else {
        return false;
      }
    },
    async requestSetTimeNotifications({ state }) {
      const response = await axios({
        url: baseUrl + "chartexperten/v1/notifications",
        method: "POST",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      if (response.status == 200) {
        return true;
      } else {
        return false;
      }
    },
    async requestGetWebinars({ state, commit }) {
      const response = await axios({
        url: baseUrl + "chartexperten/v1/webinars",
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      if (response.status == 200) {
        commit("SET_WEBINARS", response.data);
        return response.data;
      } else {
        return false;
      }
    },
    async requestGetCustomerPortal({ state, commit }) {
      const response = await axios({
        url: baseUrl + "chartexperten/v1/customer_portal",
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      });
      if (response.status == 200) {
        commit("SET_CUSTOMER_PORTAL", response.data);
        return response.data;
      } else {
        return false;
      }
    },
  },
  modules: {},
});
